<template>
  <div class="keyword">
    <div class="search">
      <el-input
        v-model="keyword"
        placeholder="请输入关键字"
        style="width: 300px; margin-right: 20px"
      ></el-input>
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="searchLabel"
      ></el-button>
    </div>
    <div class="searchList">
      <div
        v-for="(item, index) in keywordList"
        :key="index"
        :class="item.active ? 'active' : ''"
        @click="handleItem(item)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="footer">
      <el-divider></el-divider>
      <div>已选标签</div>
      <div class="selectList">
        <el-tag
          :key="index"
          v-for="(tag,index) in dynamicTags"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag.title }}
        </el-tag>
      </div>
    </div>
    <div style="text-align:center"><el-button type="primary" @click="handleSave">保存</el-button></div>
  </div>
</template>

<script>
import { request } from '../../../../../../network';
export default {
  nameL: 'Keyword',
  data () {
    return {
      keyword: '',
      keywordList: [],
      selectList: [],
      dynamicTags: []
    };
  },
  computed: {},
  methods: {
    handleItem (item) {
      item.active = !item.active;
      this.dynamicTags = this.keywordList.filter((v) => {
        return v.active === true;
      });
    },
    handleSave () {
      let str = '';
      this.dynamicTags.forEach((v, i) => {
        if (i === this.keywordList.length - 1) {
          str += v.id;
        } else {
          str += v.id + ',';
        }
      });
      request({
        method: 'POST',
        url: '/pcp/org/addMatchTags',
        data: {
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId,
          customerId: localStorage.getItem('label_id'),
          tagIds: str
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$message({
            message: res.data.data,
            type: 'success'
          });
        } else {
          this.$message({
            message: res.data.message,
            type: 'warning'
          });
        }
      }).catch(() => { this.$message.error('保存失败'); });
    },
    getQryKdTags (keyword) {
      request({
        method: 'POST',
        url: '/pcp/org/tag/qryKdTags',
        data: {
          key: keyword,
          customerId: localStorage.getItem('label_id'),
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            const a = [];
            const b = [];
            const arr = [];
            Object.keys(res.data.data).forEach((v) => {
              a.push(v);
            });
            Object.values(res.data.data).forEach((v) => {
              b.push(v);
            });
            a.forEach((v, i) => {
              arr.push({
                id: v,
                title: b[i],
                active: false
              });
            });
            this.keywordList = arr;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchLabel () {
      this.getQryKdTags(this.keyword);
    },
    handleClose (tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    }
  }
};
</script>

<style lang="less" scoped>
.keyword {
  .search {
    margin-top: 20px;
  }
  .searchList {
    display: flex;
    margin-top: 50px;
    flex-wrap: wrap;
    div {
      border-width: 1px;
      border-style: solid;
      padding: 10px 5px;
      margin-right: 20px;
      border-radius: 10px;
      width: auto;
      height: 20px;
      margin-bottom: 20px;
    }
  }
  .footer {
    margin-top: 200px;
    .selectList {
      padding: 10px 5px;
      border-radius: 10px;
    }
  }
}
.active {
  border-color: #156ED0;
  color: #156ED0;
}
.el-tag + .el-tag {
  margin-left: 10px;
  margin-bottom: 20px;
}
</style>
