import { render, staticRenderFns } from "./Keyword.vue?vue&type=template&id=460dfe16&scoped=true&"
import script from "./Keyword.vue?vue&type=script&lang=js&"
export * from "./Keyword.vue?vue&type=script&lang=js&"
import style0 from "./Keyword.vue?vue&type=style&index=0&id=460dfe16&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "460dfe16",
  null
  
)

export default component.exports