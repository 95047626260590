<template>
  <div class="classify">
    <div class="treeBox">
      <table border="1" cellpadding="0" cellspacing="0">
        <thead>
          <tr>
            <th>一级分类</th>
            <th>二级分类</th>
            <th>三级分类</th>
            <th>标签(已选择{{ checkedLabel.length }})</th>
          </tr>
        </thead>
        <tbody valign="top">
          <tr>
            <td>
              <div
                v-for="(item, index) in firstList"
                :key="index"
                style="height: 40px; line-height: 40px"
                :class="item.active ? 'active' : ''"
                @click="handleItem(item, index)"
              >
                &ensp;{{ item.name }}
              </div>
            </td>
            <td>
              <div
                v-for="(item, index) in secondList"
                :key="index"
                style="height: 40px; line-height: 40px"
                :class="item.active ? 'active' : ''"
                @click="handleItemSecond(item, index)"
              >
                &ensp;{{ item.name }}
              </div>
            </td>
            <td>
              <div
                v-for="(item, index) in threeList"
                :key="index"
                style="height: 40px; line-height: 40px"
                :class="item.active ? 'active' : ''"
                @click="handleItemThree(item, index)"
              >
                &ensp;{{ item.name }}
              </div>
            </td>
            <td>
              <el-checkbox-group v-model="checkedLabel" @change="handleCheck">
                <el-checkbox
                  v-for="(item, index) in labelList"
                  :label="item"
                  :key="index"
                >{{item.name}}</el-checkbox>
              </el-checkbox-group>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="footer">
        <div class="title">已选标签：</div>
        <el-tag
          :key="index"
          v-for="(tag, index) in selectList"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
      <!-- <div style="margin-top: 50px; text-align: center">
        <el-button type="primary" @click="save">保存</el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { request } from '../../../../../../network';
export default {
  name: 'Classify',
  data () {
    return {
      tabs: [
        { name: '一级分类' },
        { name: '二级分类' },
        { name: '三级分类' },
        { name: '标签' }
      ],
      firstList: [],
      secondList: [],
      threeList: [],
      labelList: [],
      checkedLabel: [],
      first: false,
      second: false,
      three: false,
      firstId: '',
      secondId: '',
      selectList: []
    };
  },
  mounted () {
    this.getOnetag('', '', '');
    this.getMatchTags();
  },
  methods: {
    // 不传参默认获取一级分类
    getOnetag (oneUuid, twoUuid, threeUuid) {
      const that = this;
      request({
        method: 'POST',
        url: '/pcp/org/onetag',
        data: {
          oneUuid: oneUuid,
          twoUuid: twoUuid,
          threeUuid: threeUuid,
          customerId: localStorage.getItem('label_id'),
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId
        }
      })
        .then((res) => {
          if (res.data.code === 200) {
            if (!oneUuid && !twoUuid && !threeUuid) {
              res.data.data.forEach((e) => {
                e.active = false;
              });
              that.firstList = res.data.data;
            }
            if (oneUuid && !twoUuid && !threeUuid) {
              res.data.data.group.forEach((v) => {
                v.active = false;
              });
              // 这里做清除三级分类的操作
              that.threeList = [];
              that.secondList = res.data.data.group;
              that.labelList = res.data.data.tag;
            }
            if (!oneUuid && twoUuid && !threeUuid) {
              res.data.data.group.forEach((v) => {
                v.active = false;
              });
              that.threeList = res.data.data.group;
              that.labelList = res.data.data.tag;
            }
            if (!oneUuid && !twoUuid && threeUuid) {
              that.labelList = res.data.data.tag;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMatchTags () {
      request({
        method: 'GET',
        url: '/pcp/org/getMatchTags',
        params: {
          orgID: JSON.parse(localStorage.getItem('userinfo')).orgId,
          customerId: localStorage.getItem('label_id')
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.selectList = res.data.data;
        }
      }).catch(err => { console.log(err); });
    },
    handleItem (item, index) {
      this.firstList.forEach((v, i) =>
        i === index ? (v.active = true) : (v.active = false)
      );
      this.firstId = item.uuid;
      this.getOnetag(item.uuid, '', '');
    },
    handleItemSecond (item, index) {
      this.secondList.forEach((v, i) =>
        i === index ? (v.active = true) : (v.active = false)
      );
      this.getOnetag('', item.uuid, '');
    },
    handleItemThree (item, index) {
      this.threeList.forEach((v, i) =>
        i === index ? (v.active = true) : (v.active = false)
      );
      this.getOnetag('', '', item.uuid);
    },
    handleCheck (item) {
      // this.selectList = [...this.selectList,...item]
      let str = '';
      if (this.checkedLabel.length > 0) {
        this.checkedLabel.forEach((v, i) => {
          if (i === this.checkedLabel.length - 1) {
            str += v.id;
          } else {
            str += v.id + ',';
          }
        });
        request({
          method: 'POST',
          url: '/pcp/org/addMatchTags',
          data: {
            orgId: JSON.parse(localStorage.getItem('userinfo')).orgId,
            customerId: localStorage.getItem('label_id'),
            tagIds: str,
            memberId: JSON.parse(localStorage.getItem('userinfo')).id
          }
        })
          .then((res) => {
            if (res.data.code === 200) {
              this.$message({
                message: '保存成功',
                type: 'success'
              });
              this.getOnetag('', '', '');
              this.getMatchTags();
            }
            if (res.data.code === 503) {
              this.$message({
                message: '不能重复添加已有标签',
                type: 'warning'
              });
            }
          }).catch(() => {
            this.$message.error('保存失败');
          });
      }
    },
    handleClose (tag) {
      request({
        method: 'POST',
        url: '/pcp/org/deleteMatchTag',
        data: {
          orgId: JSON.parse(localStorage.getItem('userinfo')).orgId,
          customerId: JSON.parse(localStorage.getItem('label_id')),
          tagId: tag.id,
          memberId: JSON.parse(localStorage.getItem('userinfo')).id
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$message({
            message: res.data.data,
            type: 'success'
          });
          this.getMatchTags();
        }
      }).catch(err => { console.log(err); });
    },
    save () {

    }

  }
};
</script>

<style lang="less" scoped>
.classify {
  margin-top: 50px;
  width: 90%;
  .treeBox {
    table {
      width: 100%;
      height: 100px;
      line-height: 50px;
      thead {
        tr {
          th:nth-child(1) {
            width: 15%;
          }
          tr:nth-child(2) {
            width: 15%;
          }
          tr:nth-child(3) {
            width: 15%;
          }
          th:nth-child(4) {
            width: 45%;
          }
        }
      }
    }
  }
  .footer {
    border: 2px solid #444;
    border-top: none;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .title {
      width: 100px;
    }
  }
}
.active {
  background: #156ED0;
  color: #fff;
}
.el-checkbox {
  width: 40%;
  margin-left: 20px;
}
.el-tag + .el-tag {
  margin-left: 10px;
  // margin-bottom: 10px;
}
</style>
